<template>
	<main-container :showHeader="false">
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item label="来源:">
						<el-select v-model="queryForm.source" size="mini" :clearable="true">
							<el-option v-for="item in sources" :label="item.label" :value="item.key" :key="item.key">
							</el-option>
						</el-select>
					</form-item>
					<form-item>
						<el-button type="primary" @click="searchEvent" icon="el-icon-search" size="mini">查询</el-button>
						<el-button type="primary" size="mini" @click="downloadTask">下载</el-button>
						<el-button type="primary" size="mini" @click="addSpus">添加选款</el-button>
						<el-button type="primary" size="mini" @click="showRule">规则设定</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item label="选择日期:">
						<el-date-picker v-model="queryForm.date" type="daterange" size="mini" :clearable="false"
							format="yyyy-MM-dd" value-format="yyyy-MM-dd">
						</el-date-picker>
					</form-item>
					<form-item label="商品id:">
						<el-input v-model.trim="queryForm.productId" size="mini"></el-input>
					</form-item>
					<form-item label="状态:">
						<el-select v-model="queryForm.status" size="mini" :clearable="true">
							<el-option v-for="item in listOfStatus" :label="item.label" :value="item.key"
								:key="item.key"></el-option>
						</el-select>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<el-table height="auto" :data="tableData" size="mini">
				<el-table-column label="测款轮次" prop="testRound" min-width="80" align="center">
				</el-table-column>
				<el-table-column label="HKC SPU" prop="spu" min-width="100" align="center">
				</el-table-column>
				<el-table-column label="商品图片" min-width="100">
					<template slot-scope="scope">
						<el-image style="width:80px; height: 100px" :src="scope.row.mainPic" fit="cover"></el-image>
					</template>
				</el-table-column>
				<el-table-column label="选款日期" min-width="100" align="center">
					<template slot-scope="scope">{{scope.row.createdAt | formatDate('YYYY/MM/DD')}}</template>
				</el-table-column>
				<el-table-column label="来源" min-width="75" align="center">
					<template slot-scope="scope">{{scope.row.source|getLabel(sources)}}</template>
				</el-table-column>
				<el-table-column label="imp" prop="imp" min-width="75" align="center">
				</el-table-column>
				<el-table-column label="click" prop="click" min-width="75" align="center">
				</el-table-column>
				<el-table-column label="ad_ctr" min-width="75" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.ctr">{{Number(scope.row.ctr).toFixed(2)}}%</span>
					</template>
				</el-table-column>
				<el-table-column label="user" prop="user" min-width="75" align="center">
				</el-table-column>
				<el-table-column label="sales" prop="sales" min-width="100" align="center">
				</el-table-column>
				<el-table-column label="测款开始时间" min-width="150" align="center">
					<template slot-scope="scope">{{scope.row.startAt | formatDate('YYYY/MM/DD HH:mm')}}</template>
				</el-table-column>
				<el-table-column label="测款结束时间" min-width="150" align="center">
					<template slot-scope="scope">{{scope.row.endAt | formatDate('YYYY/MM/DD HH:mm')}}</template>
				</el-table-column>
				<el-table-column label="测款广告ID" prop="ads" min-width="100" align="center">
				</el-table-column>
				<el-table-column label="测款时长(天)" prop="days" min-width="100" align="center">
				</el-table-column>
				<el-table-column label="测款期间销量" prop="sale2" min-width="100" align="center">
				</el-table-column>
				<el-table-column label="状态" min-width="100" align="center">
					<template slot-scope="scope">
						<div class="supertest-advance-dot" :class="'supertest-advance-dot__'+scope.row.status">
							{{scope.row.status | getLabel(listOfStatus)}}
							<span v-if="scope.row.failDescription">, {{scope.row.failDescription | formatError}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="75" align="center">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status === 0" type="text" size="mini" class="text-danger" @click="delProduct(scope.row)">删除
						</el-button>
					</template>
				</el-table-column>
				<el-table-column label="更新人" prop="updatedBy.name" min-width="75" align="center">
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
		<el-drawer size="80%" :visible.sync="visible.rule" :with-header="false">
			<config-rule @should-close="visible.rule=false" :key="ruleKey"></config-rule>
		</el-drawer>
	</main-container>
</template>

<script>
	import Moment from 'moment'
	import page from '@/common/mixins/page.js'
	import common from '@/common/mixins/common.js'
	import {
		mapGetters
	} from 'vuex'
	import ConfigRule from '@/components/super-test/config.vue'
	import {uuid} from 'vue-uuid'
	export default {
		mixins: [page, common],
		inject: ['adminLayout'],
		components: {
			ConfigRule
		},
		data() {
			return {
				q: {
					createdAtMinStr: Moment().add(-6, 'days').format('YYYY-MM-DD'),
					createdAtMaxStr: Moment().add(-0, 'days').format('YYYY-MM-DD')
				},
				queryForm: {
					productId: '',
					date: [
						Moment().add(-6, 'days').format('YYYY-MM-DD'),
						Moment().add(-0, 'days').format('YYYY-MM-DD')
					],
					source: null,
					status: null
				},
				tableData: [],
				namespace: 'super-test',
				visible: {
					rule: false
				},
				ruleKey: null
			}
		},
		computed: {
			...mapGetters({
				sources: 'super-test/sources',
				listOfStatus: 'super-test/listOfStatus'
			})
		},
		filters: {
			getLabel(key, list) {
				const res = list.find(v => v.key === key)
				return res && res.label
			},
			formatError(failDescription) {
				try {
					const res = JSON.parse(failDescription)
					const {days, maxDays, maxDaysTimeType, minSales, maxSales, timeType} = res.error
					if (timeType === 'TIME_FIRST_ORDER') {
						return `商品首单时间+${days}个自然天内，销量>${minSales}`
					} else if (timeType === 'LAST') {
						if (maxDaysTimeType === 'AD_CREATE_TIME') {
							return `商品测试时间+${maxDays}个自然天内连续${days}天的销量>${minSales}`
						}
						return `近${days}天，${minSales}<销量<${maxSales}`
					} else if (timeType === 'AD_CREATE_TIME') {
						return `商品测试时间+${days}个自然天内的销量>${minSales}`
					}
					return failDescription
				} catch(e) {}
			}
		},
		methods: {
			searchEvent() {
				this.q = {
					...this.queryForm,
					createdAtMinStr: this.queryForm.date[0],
					createdAtMaxStr: this.queryForm.date[1],
					spus: this.queryForm.productId.match(/[\w-]+/g)
				}
				this.page.current = 1
				this.getList()
			},
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const params = {
					...this.page,
					...this.q
				}
				return this.$store.dispatch(`${this.namespace}/getList`, {
					params,
					trigger
				})
			},
			handleList(content) {
				this.tableData = content
			},
			downloadTask() {
				this.$store.commit('download-manager/addTask', {
					action: `${this.namespace}/getList`,
					payload: {
						...this.q
					},
					title: `SUPER_TEST(${this.q.createdMinStr}~${this.q.createdMaxStr})`,
					dispatch: this.$store.dispatch,
					pagable: {
						page: 1,
						size: 50
					},
					requestHandler() {
						const params = Array.from(arguments).reduce((lhs, rhs) => {
							return {
								...lhs,
								...rhs
							}
						}, {})
						return {
							params
						}
					}
				})
			},
			showRule() {
				this.ruleKey = uuid.v4()
				this.visible.rule = true
			},
			addSpus() {
				this.$prompt('请输入SPU编号', '添加选款', {
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel',
					inputPattern: /\d+/,
					inputErrorMessage: '未检测到SPU编号'
				}).then(({
					value
				}) => {
					this.adminLayout.showLoading()
					const spus = value.match(/[\w-]+/g)
					this.$store.dispatch(`${this.namespace}/addSpus`, spus)
					.then(res => {
						this.$showSuccess('添加成功')
					})
					.catch(err => {
						this.$showErrMsg(err)
					})
					.finally(() => {
						this.adminLayout.hideLoading()
					})
				})
			},
			delProduct(row) {
				this.$confirm('此操作将中断该商品的进阶测款, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.adminLayout.showLoading()
					this.$store.dispatch(`${this.namespace}/delProduct`, row.id)
					.then(res => {
						row.status = 8
					})
					.catch(err => {
						this.$showErrMsg(err)
					})
					.finally(() => {
						this.adminLayout.hideLoading()
					})
				})
			}
		}
	}
</script>

<style lang="scss">
	.supertest-advance-dot {
		text-align: left;
		position: relative;
		padding-left: 10px;

		&:before {
			content: '';
			position: absolute;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: #BFBFBF;
			left: 0;
			top: 8px;
		}

		&__0:before {
			background-color: #BFBFBF;
		}

		&__1:before {
			background-color: #52C41A;
		}

		&__2:before {
			background-color: #1890FF;
		}

		&__8:before {
			background-color: #000000;
		}

		&__9:before {
			background-color: #F5222D;
		}
	}
</style>
