<template>
	<div class="super-test-rule-container">
		<template v-if="loading">
			<el-skeleton v-for="_ in 6" :rows="6" animated />
		</template>
		<template v-else-if="hasErr">
			<el-result icon="error" title="错误提示" subTitle="页面初始化出现异常">
				<template slot="extra">
					<el-button type="primary" size="medium" @click="loadData">重新加载</el-button>
				</template>
			</el-result>
		</template>
		<template v-else-if="instance">
			<div class="super-test-rule-container__header">
				<el-button type="primary" @click="saveData" size="mini">保存</el-button>
			</div>
			<el-card>
				<div style="overflow:auto;">
					<svg width="1078" height="1200" xmlns="http://www.w3.org/2000/svg">
						<!-- Created with Method Draw - http://github.com/duopixel/Method-Draw/ -->
						<g>
							<title>background</title>
							<rect x="-1" y="-1" width="1080" height="1202" id="canvas_background" fill="#fff" />
							<g id="canvasGrid" display="none">
								<rect id="svg_4" width="1076" height="1067" x="1" y="1" stroke-width="0"
									fill="url(#gridpattern)" />
							</g>
						</g>
						<g>
							<title>Layer 1</title>
							<rect fill="#ffffaa" stroke="#000" stroke-width="1.5" x="361" y="21.75" width="187"
								height="46" id="svg_1" />
							<text fill="#000000" stroke="#000" stroke-width="0" x="378" y="51.75" id="svg_2"
								font-size="12" font-family="Helvetica, Arial, sans-serif" text-anchor="start"
								xml:space="preserve">hekkamall服装+鞋子在架商品</text>
							<line fill="none" stroke="#000" stroke-width="1.5" x1="455" y1="69.304688" x2="454"
								y2="118.304688" id="svg_3" stroke-linejoin="undefined" stroke-linecap="undefined" />
							<path fill="none" stroke="#000" stroke-width="null" stroke-opacity="null"
								d="m449.00499,130.56281l5,-12l5,12l-10,0z" id="svg_9"
								transform="rotate(180 454.0049743652344,124.56280517578125) " />
							<path fill="#aad4ff" stroke="#000" stroke-width="null" stroke-opacity="null"
								d="m320.48795,162.31035l132.49984,-30.5l132.50014,30.5l-132.50014,30.5l-132.49984,-30.5z"
								id="svg_13" />
							<text fill="#000000" stroke="#000" stroke-width="0" x="395" y="166.75" id="svg_14"
								font-size="12" font-family="Helvetica, Arial, sans-serif" text-anchor="start"
								xml:space="preserve">是否进阶测试成功过</text>
							<line fill="none" stroke="#000" stroke-width="1.5" x1="452" y1="194.304688" x2="451"
								y2="243.304688" id="svg_15" stroke-linejoin="undefined" stroke-linecap="undefined" />
							<path fill="none" stroke="#000" stroke-width="null" stroke-opacity="null"
								d="m446.00499,255.56281l5,-11.99999l5,11.99999l-10,0z" id="svg_16"
								transform="rotate(180 451.0049743652344,249.56280517578125) " />
							<path class="svg-context" fill="#aad4ff" stroke-width="null" stroke-opacity="null"
								d="m299.48795,286.81036l151.49982,-30l151.50017,30l-151.50017,30l-151.49982,-30z"
								id="svg_17" stroke="#000" @click="tapCondition('firstProductPutRule.0.rules.0')" />
							<text font-weight="bold" xml:space="preserve" text-anchor="start"
								font-family="Helvetica, Arial, sans-serif" font-size="16" id="svg_37" y="274.038193"
								x="434.614964" stroke-opacity="null" stroke-width="0" stroke="#000" fill="#ff0000"
								class="svg-context" @click="tapCondition('firstProductPutRule.0.rules.0')">新款</text>
							<text class="svg-context" fill="#000000" stroke="#000" stroke-width="0" x="373"
								y="287.634613" id="svg_18" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve"
								@click="tapCondition('firstProductPutRule.0.rules.0')">商品首单时间({{instance.minCreatedAt | formatDate('YYYY-MM-DD')}}之后)</text>
							<text class="svg-context" fill="#000000" stroke="#000" stroke-width="0" x="385.961538"
								y="301.673075" id="svg_5" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve"
								@click="tapCondition('firstProductPutRule.0.rules.0')">
								+{{instance.firstProductPutRule[0].rules[0].days}}个自然天内，销量&gt;{{instance.firstProductPutRule[0].rules[0].minSales}}
							</text>
							<line stroke="#000" fill="none" stroke-width="1.5" x1="448" y1="316.304688" x2="447"
								y2="358.795798" id="svg_19" stroke-linejoin="undefined" stroke-linecap="undefined" />
							<path fill="none" stroke="#000" stroke-width="null" stroke-opacity="null"
								d="m442.00499,485.56281l5,-11.99999l5,11.99999l-10,0z" id="svg_20"
								transform="rotate(180 447.00500488281244,479.56280517578125) " />
							<path fill="#ffaaff" stroke-width="null" stroke-opacity="null"
								d="m295.48795,516.81036l151.49982,-30l151.50017,30l-151.50017,30l-151.49982,-30z"
								id="svg_21" stroke="#000" class="svg-context" @click="tapCondition('maxTestCount')" />
							<text fill="#000000" stroke="#000" stroke-width="0" x="392" y="520.75" id="svg_22"
								font-size="12" font-family="Helvetica, Arial, sans-serif" text-anchor="start"
								xml:space="preserve" class="svg-context"
								@click="tapCondition('maxTestCount')">是否进阶测试过{{instance.maxTestCount}}次</text>
							<rect fill="#ffffaa" stroke="#000" stroke-width="1.5" x="352" y="610.75" width="187"
								height="46" id="svg_23" />
							<text fill="#000000" stroke="#000" stroke-width="0" x="391" y="638.75" id="svg_24"
								font-size="12" font-family="Helvetica, Arial, sans-serif" text-anchor="start"
								xml:space="preserve">进入进阶测试商品池</text>
							<line fill="none" stroke="#000" stroke-width="1.5" x1="446" y1="658.304688" x2="445"
								y2="696.304688" id="svg_25" stroke-linejoin="undefined" stroke-linecap="undefined" />
							<path fill="none" stroke="#000" stroke-width="null" stroke-opacity="null"
								d="m440.00499,709.56281l5,-11.99999l5,11.99999l-10,0z" id="svg_26"
								transform="rotate(180 445.0050048828125,703.5628051757812) " />
							<line fill="none" stroke="#000" stroke-width="1.5" x1="446" y1="547.304688" x2="445"
								y2="596.304688" id="svg_33" stroke-linejoin="undefined" stroke-linecap="undefined" />
							<path fill="none" stroke="#000" stroke-width="null" stroke-opacity="null"
								d="m440.00499,608.56281l5,-11.99999l5,11.99999l-10,0z" id="svg_34"
								transform="rotate(180 445.00500488281244,602.5628051757812) " />
							<rect fill="#ffffaa" stroke-width="1.5" x="352" y="712.75" width="187" height="65.000001"
								id="svg_35" stroke="#000" />
							<text fill="#000000" stroke-width="0" x="743.893175" y="731.75" id="svg_36" font-size="12"
								font-family="Helvetica, Arial, sans-serif" text-anchor="start" xml:space="preserve"
								transform="matrix(0.8863708408066272,0,0,1,-297.82058594093553,0) "
								stroke="#000">取前一日进入进阶测试商品池的商品</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null"
								fill-opacity="null" x="357" y="749.304688" id="svg_39" font-size="12"
								font-family="Helvetica, Arial, sans-serif" text-anchor="start"
								xml:space="preserve">自动发布随机搭配广告，并记录</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="361"
								y="766.304688" id="svg_40" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">商品所在广告组测试时间</text>
							<rect fill="#ffd4aa" stroke-width="null" stroke-opacity="null" x="282" y="792.304688"
								width="315" height="318.999991" id="svg_41" stroke="#000" />
							<path class="svg-context" fill="#aad4ff" stroke="#000" stroke-width="null"
								stroke-opacity="null"
								d="m309.48795,848.31036l132.49984,-30.5l132.50014,30.5l-132.50014,30.5l-132.49984,-30.5z"
								id="svg_43" @click="tapCondition('sucessRule.0.rules.0')" />
							<text class="svg-context" fill="#000000" stroke="#000" stroke-width="0" x="359" y="852.75"
								id="svg_44" font-size="10" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve"
								@click="tapCondition('sucessRule.0.rules.0')">商品测试时间+{{instance.sucessRule[0].rules[0].days}}个自然天内的销量&gt;{{instance.sucessRule[0].rules[0].minSales}}</text>
							<path fill="none" stroke="#000" stroke-width="null" stroke-opacity="null"
								d="m438.00499,917.56281l5,-11.99999l5,11.99999l-10,0z" id="svg_46"
								transform="rotate(180 443.0049743652344,911.5628051757812) " />
							<path class="svg-context" fill="#aad4ff" stroke="#000" stroke-width="null"
								stroke-opacity="null"
								d="m309.48795,948.31036l132.49984,-30.5l132.50014,30.5l-132.50014,30.5l-132.49984,-30.5z"
								id="svg_51" @click="tapCondition('sucessRule.0.rules.1')" />
							<text class="svg-context" fill="#000000" stroke="#000" stroke-width="0" x="361" y="952.75"
								id="svg_52" font-size="10" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve"
								@click="tapCondition('sucessRule.0.rules.1')">商品测试时间+{{instance.sucessRule[0].rules[1].days}}个自然天内的销量&gt;{{instance.sucessRule[0].rules[1].minSales}}</text>
							<path fill="none" stroke="#000" stroke-width="null" stroke-opacity="null"
								d="m435.00499,1017.56281l5,-11.99999l5,11.99999l-10,0z" id="svg_54"
								transform="rotate(180 440.0049743652344,1011.5628051757812) " />
							<path class="svg-context" fill="#aad4ff" stroke="#000" stroke-width="null"
								stroke-opacity="null"
								d="m308.48795,1047.31036l132.49984,-30.5l132.50014,30.5l-132.50014,30.5l-132.49984,-30.5z"
								id="svg_55" @click="tapCondition('sucessRule.0.rules.2')" />
							<text class="svg-context" fill="#000000" stroke="#000" stroke-width="0" x="341" y="1051.75"
								id="svg_56" font-size="10" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve"
								@click="tapCondition('sucessRule.0.rules.2')">商品测试时间+{{instance.sucessRule[0].rules[2].maxDays}}个自然天内连续{{instance.sucessRule[0].rules[2].days}}天的销量&gt;{{instance.sucessRule[0].rules[2].minSales}}</text>
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="442" y1="877.304688" x2="442" y2="904.304688" id="svg_59"
								stroke-linejoin="undefined" stroke-linecap="undefined" />
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="440" y1="978.304688" x2="440" y2="1005.304688" id="svg_60"
								stroke-linejoin="undefined" stroke-linecap="undefined" />
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="442" y1="777.304688" x2="441" y2="807.304688" id="svg_61"
								stroke-linejoin="undefined" stroke-linecap="undefined" />
							<path fill="none" stroke="#000" stroke-width="null" stroke-opacity="null"
								d="m437.00499,817.56281l5,-11.99999l5,11.99999l-10,0z" id="svg_62"
								transform="rotate(180 442.0050048828125,811.5628051757812) " />
							<line fill="none" stroke="#000" stroke-width="null" stroke-opacity="null" x1="440"
								y1="1077.304688" x2="440" y2="1134.304688" id="svg_63" stroke-linejoin="null"
								stroke-linecap="null" />
							<path fill="none" stroke="#000" stroke-width="null" stroke-opacity="null"
								d="m435.00499,1146.30469l5,-11.99999l5,11.99999l-10,0z" id="svg_64"
								transform="rotate(180 440.0049743652344,1140.3046875) " />
							<rect fill="#d4ffaa" stroke="#000" stroke-width="1.5" x="349" y="1148.75" width="187"
								height="46" id="svg_65" />
							<text fill="#000000" stroke="#000" stroke-width="0" x="416" y="1176.75" id="svg_66"
								font-size="12" font-family="Helvetica, Arial, sans-serif" text-anchor="start"
								xml:space="preserve">测试成功</text>
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="296" y1="400.886414" x2="170" y2="400.886414" id="svg_68"
								stroke-linejoin="undefined" stroke-linecap="undefined" />
							<path fill="none" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								d="m158.15,407.909526l5.49999,-15l5.5,15l-10.99999,0z" id="svg_69" stroke="#000"
								transform="rotate(-90 163.6499938964844,400.40948486328125) " />
							<rect fill="#e5e5e5" stroke="#000" stroke-width="1.5" stroke-opacity="null" x="81.253729"
								y="385.886414" width="74" height="29" id="svg_70" />
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="94.253729"
								y="404.886414" id="svg_71" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">不做处理</text>
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="281" y1="949.304688" x2="218" y2="949.304688" id="svg_74"
								stroke-linejoin="undefined" stroke-linecap="undefined" />
							<path fill="none" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								d="m205.75001,956.32781l5.49999,-15l5.5,15l-10.99999,0z" id="svg_75" stroke="#000"
								transform="rotate(-90 211.25000000000003,948.8277587890625) " />
							<rect fill="#ffd4aa" stroke-width="null" stroke-opacity="null" x="1" y="917.304688"
								width="199.000007" height="66.999997" id="svg_76" stroke="#000" />
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="11"
								y="935.304688" id="svg_77" font-size="10" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">广告投放过程中，人工会根据广告数据进</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="11"
								y="947.304688" id="svg_78" font-size="10" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">行判定，关闭广告(cpc高，ctr低，或者花</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="11"
								y="961.304688" id="svg_79" font-size="10" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">满$20不出单；cpc/ctr数据好 但花费$30</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="12"
								y="976.304688" id="svg_80" font-size="10" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">不出单)或者加量</text>
							<path class="svg-context" fill="#aad4ff" stroke-width="null" stroke-opacity="null"
								d="m651.48796,286.31036l105.49986,-30.5l105.50011,30.5l-105.50011,30.5l-105.49986,-30.5z"
								id="svg_81" stroke="#000" @click="tapCondition('firstProductPutRule.1.rules.0')" />
							<text font-weight="bold" xml:space="preserve" text-anchor="start"
								font-family="Helvetica, Arial, sans-serif" font-size="16" id="svg_38" y="278.84588"
								x="733.653122" stroke-opacity="null" stroke-width="0" stroke="#000" fill="#ff0000"
								class="svg-context" @click="tapCondition('firstProductPutRule.1.rules.0')">潜力款</text>
							<text class="svg-context" fill="#000000" stroke="#000" stroke-width="0" x="709"
								y="296.557688" id="svg_82" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve"
								@click="tapCondition('firstProductPutRule.1.rules.0')">
								近{{instance.firstProductPutRule[1].rules[0].days}}天，{{instance.firstProductPutRule[1].rules[0].minSales}}&lt;销量&lt;{{instance.firstProductPutRule[1].rules[0].maxSales}}
							</text>
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="603" y1="286.304688" x2="638" y2="286.304688" id="svg_83"
								stroke-linejoin="undefined" stroke-linecap="undefined" />
							<path fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								d="m640.61502,291.2828l4.00001,-10.99999l4,10.99999l-8.00001,0z" id="svg_84"
								transform="rotate(90 644.6150512695314,285.78280639648443) " />
							<rect fill="#e5e5e5" stroke="#000" stroke-width="1.5" stroke-opacity="null" x="916"
								y="273.304688" width="74" height="29" id="svg_85" />
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="929"
								y="292.304688" id="svg_86" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">不做处理</text>
							<line stroke="#000" fill="none" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="862.761209" y1="287.304688" x2="902.238798" y2="287.304688" id="svg_87"
								stroke-linejoin="undefined" stroke-linecap="undefined" />
							<path fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								d="m904.853815,292.28281l4.00001,-10.99999l4,10.99999l-8.00001,0z" id="svg_88"
								transform="rotate(90 908.8538208007812,286.7828063964845) " />
							<path class="svg-context" fill="#aad4ff" stroke-width="null" stroke-opacity="null"
								d="m843.48796,516.31036l105.49986,-30.5l105.50011,30.5l-105.50011,30.5l-105.49986,-30.5z"
								id="svg_89" stroke="#000" @click="tapCondition('failedEverProductPutRule.0.rules.0')" />
							<text class="svg-context" fill="#000000" stroke="#000" stroke-width="0" x="901" y="521.75"
								id="svg_90" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve"
								@click="tapCondition('failedEverProductPutRule.0.rules.0')">近{{instance.failedEverProductPutRule[0].rules[0].days}}天，{{instance.failedEverProductPutRule[0].rules[0].minSales}}&lt;销量&lt;{{instance.failedEverProductPutRule[0].rules[0].maxSales}}</text>
							<rect fill="#ffaad4" stroke="#000" stroke-width="1.5" x="856" y="752.75" width="187"
								height="46" id="svg_91" />
							<text fill="#000000" stroke="#000" stroke-width="0" x="914" y="778.75" id="svg_92"
								font-size="12" font-family="Helvetica, Arial, sans-serif" text-anchor="start"
								xml:space="preserve">本轮测试失败</text>
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" x1="611"
								y1="516.304688" x2="844" y2="516.304688" id="svg_93" stroke-linejoin="null"
								stroke-linecap="null" />
							<path fill="none" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								d="m597.75001,523.32781l5.49999,-15l5.5,15l-10.99999,0z" id="svg_96" stroke="#000"
								transform="rotate(-90 603.2500000000001,515.8277587890625) " />
							<line fill="none" stroke-width="1.5" stroke-opacity="null" fill-opacity="null" x1="584"
								y1="161.304688" x2="953.538443" y2="163.304688" id="svg_97" stroke-linejoin="null"
								stroke-linecap="null" stroke="#000" />
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="954" y1="260.304688" x2="954" y2="162.304688" id="svg_98" stroke-linejoin="null"
								stroke-linecap="null" />
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="1076" y1="323.304688" x2="1076" y2="324.304688" id="svg_99" stroke-linejoin="null"
								stroke-linecap="null" />
							<path fill="none" stroke="#000" stroke-width="null" stroke-opacity="null"
								d="m949.53844,272.48582l5,-11.99999l5,11.99999l-10,0z" id="svg_100"
								transform="rotate(180 954.5384521484375,266.48577880859375) " />
							<line stroke="#000" fill="none" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="757.22772" y1="316.764999" x2="757.458494" y2="400.311342" id="svg_102"
								stroke-linejoin="null" stroke-linecap="null" />
							<line stroke="#000" fill="none" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="949.829253" y1="483.750751" x2="949.53474" y2="318.304689" id="svg_103"
								stroke-linejoin="null" stroke-linecap="null" />
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="951.073156" y1="751.304688" x2="950.303928" y2="561.304688" id="svg_104"
								stroke-linejoin="null" stroke-linecap="null" />
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="951.073156" y1="813.304688" x2="948.765472" y2="1051.304688" id="svg_105"
								stroke-linejoin="null" stroke-linecap="null" />
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="572.613039" y1="1046.301119" x2="949" y2="1052.454942" id="svg_106"
								stroke-linejoin="null" stroke-linecap="null" />
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="573.382266" y1="947.070723" x2="949.5347" y2="951.68609" id="svg_107"
								stroke-linejoin="null" stroke-linecap="null" />
							<line fill="none" stroke="#000" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="573.382266" y1="847.071098" x2="950.303928" y2="850.14801" id="svg_108"
								stroke-linejoin="null" stroke-linecap="null" />
							<path fill="none" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								d="m945.44103,813.32671l5.49999,-15l5.5,15l-10.99999,0z" id="svg_109" stroke="#000" />
							<path fill="none" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								d="m944.6718,561.01996l5.49999,-15l5.5,15l-10.99999,0z" id="svg_110" stroke="#000" />
							<path fill="none" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								d="m943.90257,317.63585l5.49999,-15l5.5,15l-10.99999,0z" id="svg_111" stroke="#000" />
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="706.461713"
								y="155.839291" id="svg_112" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">是</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="716.461713"
								y="530.505962" id="svg_113" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">是</text>
							<text fill="#000000" stroke-width="0" stroke-opacity="null" x="699.736081" y="393.406777"
								id="svg_114" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve" stroke="#000">是</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="220.1599"
								y="396.309917" id="svg_115" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">是</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="450.906171"
								y="898.283733" id="svg_116" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">是</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="450.906171"
								y="1000.505955" id="svg_117" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">是</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="449.79506"
								y="1123.83929" id="svg_118" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">是</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="460.222222"
								y="222.749132" id="svg_119" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">否</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="614.666665"
								y="278.304689" id="svg_120" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">否</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="954.666665"
								y="402.559628" id="svg_121" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">否</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="741.333337"
								y="842.971346" id="svg_122" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">否</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="739.111115"
								y="938.526903" id="svg_123" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">否</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="738.000003"
								y="1039.638017" id="svg_124" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">否</text>
							<text fill="#000000" stroke-width="0" stroke-opacity="null" x="433.128393" y="339.172625"
								id="svg_125" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve" stroke="#000">是</text>
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="450.222222"
								y="580.749132" id="svg_126" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">否</text>
							<path fill="#ffaaff" stroke-width="null" stroke-opacity="null"
								d="m295.48795,400.81036l151.49982,-30l151.50017,30l-151.50017,30l-151.49982,-30z"
								id="svg_7" stroke="#000" />
							<text fill="#000000" stroke="#000" stroke-width="0" x="407.38465" y="404.75" id="svg_8"
								font-size="12" font-family="Helvetica, Arial, sans-serif" text-anchor="start"
								xml:space="preserve">是否进阶测试过</text>
							<path fill="none" stroke="#000" stroke-width="null" stroke-opacity="null"
								d="m442.00499,371.56281l5,-11.99999l5,11.99999l-10,0z" id="svg_10"
								transform="rotate(180 447.0050048828125,365.56280517578125) " />
							<line stroke="#000" fill="none" stroke-width="1.5" stroke-opacity="null" x1="611"
								y1="401.304688" x2="757.212844" y2="401.304688" id="svg_11" stroke-linejoin="null"
								stroke-linecap="null" />
							<path fill="none" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								d="m597.75001,408.32781l5.49999,-15l5.5,15l-10.99999,0z" id="svg_12" stroke="#000"
								transform="rotate(-90 603.2500000000001,400.82775878906256) " />
							<line stroke="#000" fill="none" stroke-width="1.5" x1="448" y1="430.506127" x2="447"
								y2="472.997237" id="svg_27" stroke-linejoin="undefined" stroke-linecap="undefined" />
							<line stroke="#000" fill="none" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								x1="295.253732" y1="516.558419" x2="116.268489" y2="516.558419" id="svg_28"
								stroke-linejoin="undefined" stroke-linecap="undefined" />
							<path fill="none" stroke-width="1.5" stroke-opacity="null" fill-opacity="null"
								d="m111.881199,431.043929l5.49999,-15l5.5,15l-10.99999,0z" id="svg_29" stroke="#000" />
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="453.207306"
								y="458.360689" id="svg_30" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">否</text>
							<line stroke="#000" fill="none" stroke-width="1.5" x1="117.401947" y1="430.506121"
								x2="116.401947" y2="515.534698" id="svg_31" stroke-linejoin="undefined"
								stroke-linecap="undefined" />
							<text fill="#000000" stroke="#000" stroke-width="0" stroke-opacity="null" x="195.532958"
								y="511.981922" id="svg_32" font-size="12" font-family="Helvetica, Arial, sans-serif"
								text-anchor="start" xml:space="preserve">是</text>
						</g>
					</svg>
				</div>
			</el-card>
		</template>
		<el-dialog title="编辑" :visible.sync="visible.edit" width="900px" :append-to-body="true">
			<template v-if="aKey==='firstProductPutRule.0.rules.0'">
				<div>
					商品首单时间
					<el-date-picker v-model="rule.minCreatedAt" type="datetime" value-format="timestamp"
						:clearable="false" size="mini">
					</el-date-picker>
					之后
					+ <el-input-number v-model="rule.days" :step="1" size="mini" :min="1" controls-position="right"
						class="mx-1"></el-input-number>个自然天内，销量 &gt; <el-input-number v-model="rule.minSales" :step="1"
						:min="1" controls-position="right" size="mini" class="mx-1"></el-input-number>
				</div>
			</template>
			<template v-if="aKey==='firstProductPutRule.1.rules.0' || aKey === 'failedEverProductPutRule.0.rules.0'">
				近<el-input-number v-model="rule.days" :step="1" size="mini" :min="1" controls-position="right"
					class="mx-1"></el-input-number>天，<el-input-number v-model="rule.minSales" :step="1" :min="1"
					controls-position="right" size="mini" class="mx-1"></el-input-number>&lt; 销量 &lt; <el-input-number
					v-model="rule.maxSales" :step="1" :min="1" controls-position="right" size="mini" class="mx-1">
				</el-input-number>
			</template>
			<template v-if="aKey==='maxTestCount'">
				是否进阶测试过 <el-input-number v-model="rule" :step="1" size="mini" :min="1" controls-position="right"
					class="mx-1"></el-input-number>次
			</template>
			<template v-if="aKey==='sucessRule.0.rules.0' || aKey === 'sucessRule.0.rules.1'">
				商品测试时间+<el-input-number v-model="rule.days" :step="1" size="mini" :min="1" controls-position="right"
					class="mx-1"></el-input-number>个自然天内的销量 &gt; <el-input-number v-model="rule.minSales" :step="1"
					:min="1" controls-position="right" size="mini" class="mx-1"></el-input-number>
			</template>
			<template v-if="aKey==='sucessRule.0.rules.2'">
				商品测试时间+<el-input-number v-model="rule.maxDays" :step="1" size="mini" :min="1" controls-position="right"
					class="mx-1"></el-input-number>个自然天内连续<el-input-number v-model="rule.days" :step="1" size="mini"
					:min="1" controls-position="right" class="mx-1"></el-input-number>天的销量 &gt; <el-input-number
					v-model="rule.minSales" :step="1" :min="1" controls-position="right" size="mini" class="mx-1">
				</el-input-number>
			</template>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.edit = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="confirmEdit" size="mini">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Lodash from 'lodash'
	const DEFAULT_INSTANCE = {
		maxTestCount: 2,
		firstProductPutRule: [{
				operator: "and",
				rules: [{
					timeType: "TIME_FIRST_ORDER",
					days: 7,
					maxDays: null,
					maxDaysTimeType: null,
					minSales: 2,
					maxSales: null,
					minCreatedAt: null
				}]
			},
			{
				operator: "and",
				rules: [{
					timeType: "LAST",
					days: 3,
					maxDays: null,
					maxDaysTimeType: null,
					minSales: 3,
					maxSales: 18
				}]
			}
		],
		failedEverProductPutRule: [{
			operator: "and",
			rules: [{
				timeType: "LAST",
				days: 3,
				maxDays: null,
				maxDaysTimeType: null,
				minSales: 5,
				maxSales: 18
			}]
		}],
		sucessRule: [{
			operator: "and",
			rules: [{
				timeType: "AD_CREATE_TIME",
				days: 3,
				maxDays: null,
				maxDaysTimeType: null,
				minSales: 5,
				maxSales: null
			}, {
				timeType: "AD_CREATE_TIME",
				days: 7,
				maxDays: null,
				maxDaysTimeType: null,
				minSales: 7,
				maxSales: null
			}, {
				timeType: "LAST",
				days: 7,
				maxDays: 14,
				maxDaysTimeType: "AD_CREATE_TIME",
				minSales: 17,
				maxSales: null
			}]
		}]
	}
	export default {
		inject: ['adminLayout'],
		data() {
			return {
				timeTypes: {
					TIME_FIRST_ORDER: '商品首单时间',
					AD_CREATE_TIME: '商品测试时间',
					LAST: '最近几天'
				},
				visible: {
					edit: false
				},
				aKey: null,
				instance: null, // Lodash.cloneDeep(DEFAULT_INSTANCE),
				rule: null,
				namespace: 'super-test',
				loading: false,
				hasErr: false
			}
		},
		methods: {
			tapCondition(aKey) {
				// firstProductPutRule.0.rules.0
				// firstProductPutRule.1.rules.0
				// maxTestCount
				// failedEverProductPutRule.0.rules.0
				// sucessRule.0.rules.0
				// sucessRule.0.rules.1
				// sucessRule.0.rules.2
				const rule = aKey.split('.').reduce((v, k) => {
					if (/^\d+$/.test(k)) {
						return v[parseInt(k)]
					}
					return v[k]
				}, this.instance)
				if (typeof rule === 'object') {
					const mockRule = aKey.split('.').reduce((v, k) => {
						if (/^\d+$/.test(k)) {
							return v[parseInt(k)]
						}
						return v[k]
					}, DEFAULT_INSTANCE)
					if (aKey === 'firstProductPutRule.0.rules.0') {
						rule.minCreatedAt = this.instance.minCreatedAt
					}
					this.rule = {
						...Lodash.cloneDeep(mockRule),
						...Lodash.cloneDeep(rule)
					}
				} else {
					this.rule = rule
				}
				this.aKey = aKey
				this.visible.edit = true
			},
			loadData() {
				if (this.loading) return
				this.hasErr = false
				this.loading = true
				this.$store.dispatch(`${this.namespace}/getConfig`)
					.then(({
						config
					}) => {
						this.instance = config
					})
					.finally(() => {
						if (this.instance === null) {
							this.hasErr = true
						}
						this.loading = false
					})
			},
			confirmEdit() {
				const rule = this.aKey.split('.').reduce((v, k) => {
					if (/^\d+$/.test(k)) {
						return v[parseInt(k)]
					}
					return v[k]
				}, this.instance)
				if (typeof rule === 'object') {
					Object.assign(rule, this.rule)
					if (this.aKey === 'firstProductPutRule.0.rules.0') {
						this.instance.minCreatedAt = rule.minCreatedAt
					}
				} else if (typeof rule === 'number') {
					this.instance[this.aKey] = this.rule
				}
				this.visible.edit = false
			},
			saveData() {
				this.adminLayout.showLoading(true)
				this.$store.dispatch(`${this.namespace}/updateConfig`, Lodash.cloneDeep(this.instance))
					.then(res => {
						this.$showSuccess('更新成功')
						this.$emit('should-close')
					})
					.catch(err => {
						this.$showErrMsg(err)
					})
					.finally(() => {
						this.adminLayout.hideLoading()
					})
			}
		},
		mounted() {
			this.loadData()
		}
	}
</script>

<style lang="scss">
	.svg-context {
		cursor: pointer;
	}

	.super-test-rule-container {
		padding: 20px;

		&__header {
			position: sticky;
			top: 20px;
			text-align: right;
			margin-bottom: 20px;
		}
	}
</style>
